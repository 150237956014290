import { api } from ":mods";
import { ENDPOINTS } from "../const";

export async function getCommisions() {
  return api
    .getAuth(ENDPOINTS.COMMISSIONS)
    .then(async (res) => {
      // console.log("commissions :: ", res);
      const coms = res.data;
      const coms_arr = [];
      for (const com of coms) {
        coms_arr.push(com.name);
      }
      return Promise.resolve(coms_arr);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
